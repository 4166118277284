import React from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
// import { slide as Menu } from 'react-burger-menu';
// import { Link } from 'react-router-dom';
//Import the images from your src folder
import founderImage from './assets/images/Corn Garden Mita.jpg';
import consultantImage from './assets/images/Contact_Phylis.jpg';
import marketerImage from './assets/images/ContactMwango.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram} from '@fortawesome/free-brands-svg-icons'; //faTiktok, faTwitterSquare 

function ContactUs() {

  const navigate = useNavigate();

  const teamMembers = [
    {
      name: "Moses Ooko",
      title: "CEO | Founder | Service Provider",
      bio: "I am a professional organic expert and consultant for more than 5 years.",
      image: founderImage
    },
    {
      name: "Phylis Andambi",
      title: "Co-Founder | Professional Advisor",
      bio: "I am a professional expert and consultant in Agriculture and agri-nutrition for over 25 years.",
      image: consultantImage
    },
    {
      name: "Michael Kimeu",
      title: "Head of Marketing | Software Engineer",
      bio: "I am a professional marketer with more than one year of experience",
      image: marketerImage
    },
  ]; 

  return (
    <div className = "contact-us">
      {/* <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
        <Link id="home" className="menu-item" to="/">Home</Link>
        <Link id="about" className="menu-item" to="/about-us">About Us</Link>
        <Link id="services" className="menu-item" to="/services">Services</Link>
        <Link id="photoworks" className="menu-item" to="/photoworks">Photo Works</Link>
        <Link id="videoworks" className="menu-item" to="/videoworks">Video Works</Link>
      </Menu> */}
      <h1>Meet the Team</h1>
      <h2> "It always seems impossible until its done." </h2>
      <div className = "contact-container">
        {teamMembers.map((member, index) => (
          <React.Fragment key={index}>

            <div className = "team-member" key={index}>
              <img src={member.image} alt={member.name} className="member-image" />
              <h3>{member.name}</h3>
              <p className= "member-role">{member.title}</p>
              <p className= "member-bio">{member.bio}</p>
            </div>

           {index < teamMembers.length - 1 && <hr className="member-divider" />} {/* Only add <hr /> if it's not the last item */}
           
          </React.Fragment>
        ))}
      </div>

      {/* Social Media Links */}
      <div className="social-media-links">
        <h3>Connect with us on social media</h3>
        <div className="social-media-icons">
              <a href="https://www.facebook.com/profile.php?id=61555764148696" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" color="#3b5998" />
              </a>
              <a href="https://www.instagram.com/agri_resilient/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x" color="#C13584" />
              </a>
              {/* <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitterSquare} size="2x" color="#1DA1F2" />
              </a>
              <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTiktok} size="2x" color="#000000" />
              </a> */}
              {/* <FaYoutubeSquare className="fa-2x" /> */}
          {/* <FaYoutubeSquare className="fa-2x" /> */}
      </div>
      </div>
      <Button 
        sx={{
          position: 'fixed',
          color: 'success.dark',
          left: 16,
          font: 'bold',
          bottom: 16,
          marginRight: 3,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'success.light',
          },}}
        variant="contained"
        onClick={() => navigate('/videoworks')}>
        Back
      </Button>  
    </div>
  );
}

export default ContactUs;