// NotFound.js
import React from 'react';
import './App.css';

function NotFound () {
  return (
    <div class="container-error">
        <h1>404</h1>
        <p>Oops! The page you're looking for isn't here.</p>
        <p>You might have the wrong address, or the page may have moved.</p>
        <a href="https://www.instagram.com/agri_resilient/" target="_blank" rel="noopener noreferrer">Visit Our Instagram</a>
    </div>
  );
};

export default NotFound;
