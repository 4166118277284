import React from "react";
import './App.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
// import { slide as Menu } from 'react-burger-menu';
// import { Link } from 'react-router-dom';

function AboutUs() {
  const navigate = useNavigate();

  function Footer () {
    // get the current year
    const year = new Date().getFullYear();
    return (
      <footer>
        <p>&copy; {year} Agri-Resilient Agency</p>
      </footer>
    );
  }

  // function handleClick() {
  //   navigate('/services');
  // }
  return (
     <div className="about-us"  > 
          {/* <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
            <Link id="home" className="menu-item" to="/">Home</Link>
            <Link id="services" className="menu-item" to="/services">Services</Link>
            <Link id="photoworks" className="menu-item" to="/photoworks">Photo Works</Link>
            <Link id="videoworks" className="menu-item" to="/videoworks">Video Works</Link>
            <Link id="contact" className="menu-item" to="/contact-us">Contact Us</Link>
          </Menu> */}
      <div className="about-us">
        <div className="text-container">
          <h1>Who We Are</h1>
          <p>
            As a registered organization that specializes in agribusiness, we are committed to promoting climate-smart agriculture and 
            innovative organic farming technologies. Our main goal is to reduce the impact of climate change by leveraging organic 
            agricultural innovations and technologies to improve food security and increase employment opportunities.
          </p>
          {/* <h1>Our Vision</h1> */}
          <p>Vision: To be the number one agricultural brand anchored on community lead initiatives towards climate smart resilience agriculture,
              technologies and innovation leveraging on organic farming.
          </p>
          {/* <h1>Our Mission</h1> */}
          <p>Mission: To provide optimal services to customers by respecting all their requests 
              and involving the community using new technology and 
                innovation while ensuring environmental conversation.
          </p>
        </div>
        <Footer />
      </div>
      <Button 
        sx={{
          position: 'fixed',
          color: 'success.dark',
          left: 16,
          font: 'bold',
          bottom: 16,
          marginLeft: 3,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'success.light',
          },}}
        variant="contained"
        onClick={() => navigate('/')}>
        Back
      </Button>

      <Button 
        sx={{
          position: 'fixed',
          color: 'success.dark',
          right: 16,
          font: 'bold',
          bottom: 16,
          marginRight: 3,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'success.light',
          },}}
        variant="contained"
        onClick={() => navigate('/services')}>
        Next
      </Button>
    </div>
  );
}

export default AboutUs;
