import React from 'react';
import './App.css';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid'; //to display the cards in a grid-like partitions
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import image1 from './assets/images/Corn Garden Mita.jpg';
import image2 from './assets/images/Rack.jpg';
import image3 from './assets/images/Moist Garden 2.jpg';
import image4 from './assets/images/Large Zie Pit.jpg';
import image5 from './assets/images/Corn Gardens in UV radiation.jpg';
import image6 from './assets/images/Zie Pits with Kales.jpg';
import image7 from './assets/images/Moist Garden in UV protection.jpg';
import image8 from './assets/images/Corn Garden Mita.jpg';
// import { slide as Menu } from 'react-burger-menu';
// import { Link } from 'react-router-dom';

import { Button, CardActionArea, CardActions } from '@mui/material';

function PhotoWorks() {
    // Assuming you have multiple images or cards
    const [activeCard, setActiveCard] = React.useState(null);
    const images = [
      { img: image1, title: "Corn Garden", description: "This is a corn garden. One of the widely used technologies when you have a relative small area to do vegetables " },
      { img: image2, title: "Rack", description: "A technology for putting and selling harvested vegetables." },
      { img: image3, title: "Moist Bed Garden", description: "A technology for water conversation in the production of vegetables and shrub fruits." },
      { img: image4, title: "Zie Pits", description: "A technology for water conversation in crop production." },
      { img: image5, title: "Corn Garden with UV Protection", description: "Corn Gardens in a home made green house." },
      { img: image6, title: "Zie pits with kales", description: "Planting kales in a zie pit on a larger field." },
      { img: image7, title: "Moist Bed Garden", description: "Moist Bed Garden in a home made green house." },
      { img: image8, title: "Corn Garden", description: "A corn garden gives you your own garden of vegetables." },
      //{ img: image2, title: "Open Farm", description: "This is an open farm" },
    ]; // Replace with actual different images e.g image1, image2, image3, etc

    const navigate = useNavigate();

    // Function to open the modal with card details
    const handleOpen = (card) => {
      setActiveCard(card);
    };

    // Function to close the modal
    const handleClose = () => {
      setActiveCard(null);
    };

      return (
        <Box sx={{ flexGrow: 1 }}>
          {/* <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
            <Link id="home" className="menu-item" to="/">Home</Link>
            <Link id="about" className="menu-item" to="/about-us">About Us</Link>
            <Link id="services" className="menu-item" to="/services">Services</Link>
            <Link id="videoworks" className="menu-item" to="/videoworks">Video Works</Link>
            <Link id="contact" className="menu-item" to="/contact-us">Contact Us</Link>
          </Menu> */}
          <Grid container spacing = {4} className="scroll-container">
          {images.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card key={index} 
                  sx={{ maxWidth: 600, height: '100%' }}
                  onClick={() => handleOpen(card)}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={ card.img }
                    alt={ card.title }
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      { card.title }
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      { card.description }
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" font = "bold" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
  
          {/* Modal to show the card details */}
            {activeCard && 
            (
              <Modal open={true} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* <Card sx={{ maxWidth: 500 }}> */}
                <Card sx={{ width: '80%' , maxWidth: '100vh' }}>
                  <CardMedia component="img" height="500" image={activeCard.img} alt={activeCard.title} />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">{activeCard.title}</Typography>
                    <Typography variant="body2" color="text.secondary">{activeCard.description}</Typography>
                  </CardContent>
                </Card>
              </Modal>
            )}
          </Grid>
          <Button 
            sx={{
              position: 'fixed',
              color: 'success.dark',
              left: 16,
              font: 'bold',
              bottom: 16,
              marginLeft: 3,
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'success.light',
              },}}
            variant="contained"
            onClick={() => navigate('/services')}>
            Back
          </Button>
          <Button 
            sx={{
              position: 'fixed',
              color: 'success.dark',
              right: 16,
              font: 'bold',
              bottom: 16,
              marginRight: 3,
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'success.light',
              },}}
            variant="contained"
            onClick={() => navigate('/videoworks')}>
            Next
          </Button>
        </Box>
    )};

  export default PhotoWorks;

