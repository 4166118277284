import React from 'react';
import './App.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
// import { slide as Menu } from 'react-burger-menu';
// import { Link } from 'react-router-dom';


function Footer () {
  // get the current year
  const year = new Date().getFullYear();
  return (
    <footer>
      <p>&copy; {year} Agri-Resilient Agency</p>
    </footer>
  );
}

function App() {

  const navigate = useNavigate();

  // function handleClick() {
  //   navigate('/about-us');
  // }

  return (
    <div className="Landing"  > 
      {/* <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
        <Link id="about" className="menu-item" to="/about-us">About Us</Link>
        <Link id="services" className="menu-item" to="/services">Services</Link>
        <Link id="photoworks" className="menu-item" to="/photoworks">Photo Works</Link>
        <Link id="videoworks" className="menu-item" to="/videoworks">Video Works</Link>
        <Link id="contact" className="menu-item" to="/contact-us">Contact Us</Link>
     </Menu> */}
      <header className="Landing-header">
          <div className='text-container'>
            <h1>
              AGRI-RESILIENT AGENCY
            </h1>
            <p>Organic Works</p>
            <Footer />
          </div>
        </header>
        <Button 
            sx={{
              position: 'fixed',
              color: 'success.dark',
              right: 16,
              font: 'bold',
              bottom: 16,
              marginRight: 3,
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'success.light',
              },}}
            variant="contained"
            onClick={() => navigate('/about-us')}>
            Know Us
          </Button>
    </div>
  );
}

export default App;
