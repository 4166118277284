import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import './App.css';
import video from './assets/videos/Value.mp4';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material'; 
// import { slide as Menu } from 'react-burger-menu';
// import { Link } from 'react-router-dom';

const videos = [
  {
    title: "Corn Garden",
    description: "Organic technology is the future of farming",
    video: video
  },
  // More videos
];

function VideoWorks () {
  const navigate = useNavigate();

  // function handleClick() {
  //   navigate('/contact-us');
  // }

  return (
    <div className = "">
      <Grid container spacing={2} className = "videowork">
        {/* <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
          <Link id="home" className="menu-item" to="/">Home</Link>
          <Link id="about" className="menu-item" to="/about-us">About Us</Link>
          <Link id="services" className="menu-item" to="/services">Services</Link>
          <Link id="photoworks" className="menu-item" to="/photoworks">Photo Works</Link>
          <Link id="contact" className="menu-item" to="/contact-us">Contact Us</Link>
        </Menu> */}
        {videos.map((video, index) => (
          <Grid item xs={12} sm={6} md={10} key={index}>
            <Card>
              <CardMedia
                component="video"
                controls
                image={video.video}
                alt={video.title}
              />
              <CardContent className = "card-content" >
                <Typography gutterBottom variant="h5" component="div">
                  {video.title}
                </Typography>
                <Typography variant="body2" >
                  {video.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button 
      sx={{
        position: 'fixed',
        color: 'success.dark',
        left: 16,
        font: 'bold',
        bottom: 16,
        marginLeft: 3,
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'success.light',
        },}}
      variant="contained"
      onClick={() => navigate('/photoworks')}>
      Back
    </Button>

    <Button 
      sx={{
        position: 'fixed',
        color: 'success.dark',
        right: 16,
        font: 'bold',
        bottom: 16,
        marginRight: 3,
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'success.light',
        },}}
      variant="contained"
      onClick={() => navigate('/contact-us')}>
      Next
    </Button>
  </div>
  );
}

export default VideoWorks;
