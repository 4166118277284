import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AboutUs from './AboutUs';
// import Vision from './Vision';
import Services from './Services';
import PhotoWorks from './PhotoWorks';
import VideoWorks from './VideoWorks';
import ContactUs from './ContactUs';
import NotFound from './404Error';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import BrowserRouter, Routes, and Route


const agriculture = ReactDOM.createRoot(document.getElementById('root'));
agriculture.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about-us" element={<AboutUs />} /> {/* Set up the route for AboutUs */}
        {/* <Route path="/vision" element={<Vision />} /> Set up the route for Vision  */}
        <Route path="/services" element={<Services />} /> {/* Set up the route for Services */}
        <Route path="/photoworks" element={<PhotoWorks/>} />
        <Route path="/videoworks" element={<VideoWorks/>} />
        <Route path="/contact-us" element={<ContactUs />} /> {/* Set up the route for ContactUs */}
        <Route path="*" element={<NotFound />} />  {/*Set up the route for NotFound */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
