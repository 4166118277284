// Services.js
import React from 'react';
import './App.css';
import { FaLeaf } from 'react-icons/fa'; // Example icon from react-icons
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
// import { slide as Menu } from 'react-burger-menu';
// import { Link } from 'react-router-dom';

const services = [
  { id: 1, text: "Organic crop production and management technologies" },
  { id: 2, text: "Urban and peri-urban agricultural technologies" },
  { id: 3, text: "Organic livestock production and management" },
  { id: 4, text: "Organic waste management" },
  { id: 5, text: "Value addition and organic food processing" },
  { id: 6, text: "Organic products marketing" },
  { id: 7, text: "Good agricultural practices" },
  { id: 8, text: "Farmer's training on organic farming technologies" }
];


function Services() {

  const navigate = useNavigate();

  // function handleClick() {
  //   navigate('/photoworks');
  // }
    return (
     <div className="photoworks"> 
        {/* <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
          <Link id="home" className="menu-item" to="/">Home</Link>
          <Link id="about" className="menu-item" to="/about-us">About Us</Link>
          <Link id="photoworks" className="menu-item" to="/photoworks">Photo Works</Link>
          <Link id="videoworks" className="menu-item" to="/videoworks">Video Works</Link>
          <Link id="contact" className="menu-item" to="/contact-us">Contact Us</Link>
        </Menu> */}
        <div className="services-container">
        {/* <div className="services-image">
          <img src="src/assets/images/Services.png" alt="Sunset over a field" />
        </div> */}
        <div className="services-info">
          <h1>Our Services</h1>
          <ul className="services-list">
            {services.map((service) => (
              <li key={service.id}>
                <FaLeaf className="service-icon" />
                <span>{service.text}</span>
              </li>
            ))}
          </ul>
        </div>
        <Button 
        sx={{
          position: 'fixed',
          color: 'success.dark',
          left: 16,
          font: 'bold',
          bottom: 16,
          marginLeft: 3,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'success.light',
          },}}
        variant="contained"
        onClick={() => navigate('/about-us')}>
        Back
      </Button>

      <Button 
        sx={{
          position: 'fixed',
          color: 'success.dark',
          right: 16,
          font: 'bold',
          bottom: 16,
          marginRight: 3,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'success.light',
          },}}
        variant="contained"
        onClick={() => navigate('/photoworks')}>
        Next
      </Button>
      </div>
     </div>
      
    );
  }



export default Services;

